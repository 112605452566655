import React, { useContext } from 'react';
import DocumentPage, { DocumentPageContext } from '../components/DocumentPage';
import OfferCard from '../components/OfferCard';
import { CustomerBM, OfferBM, ServicelogV2BM } from '@bm-js/h2o-shared';
import { navigate } from 'gatsby';
import { request } from '../utils/request';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { useQuery } from '../utils/hooks';
import { parseCustomerName } from '../utils/parseCustomerName';

const context: DocumentPageContext<OfferBM> = {
  documentName: 'Offert',
  documentNameSpecific: 'Offerten',
  documentKey: 'offer',
  documentKeyPlural: 'offers',
  documentNamePlural: 'Offerter',
  internalPersonKey: 'sellerReferences',
  contactPersonKey: 'customerReferences',
  pdfNeedsFullCustomer: true,
  enablePdfUpload: true,
  documentIdentifier: 'offerNumber',
  cardComponent: OfferCard,
  internalPersonKeyRequired: true,
  cardGridTemplate: '1fr 1fr 1fr 1fr 1fr 20px',
  cardHeaders: [
    {
      text: 'Offertnummer',
      mobile: true,
    },
    {
      text: 'Offertdatum',
    },
    {
      text: 'Antal rader',
    },
    {
      text: 'Summa',
    },
    {
      text: 'Status',
      mobile: true,
    },
  ],
};

const OffersPage = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const { name: customerName, id: customerId } = useQuery<{
    name: string;
    id: string;
  }>();

  const createServicelogFromOffer = async (offer: OfferBM) => {
    const { err: mongoErr, data } = await request<ServicelogV2BM>({
      state,
      dispatch,
      path: `offers/create-servicelog-from-offer/${offer._id}`,
      method: 'POST',
    });
    if (mongoErr || !data) return;

    const { data: customer, err: customerErr } = await request<CustomerBM>({
      state,
      dispatch,
      path: `customers/single/${customerId}`,
    });

    if (customerErr || !customer) return;

    const parsedCustomer = {
      ...customer,
      name: customerName,
      id: customerId,
      reservoirs: [],
      communications: [],
    };

    const { err: doErr } = await request({
      state,
      dispatch,
      method: 'POST',
      isDoServer: true,
      body: { ...data, customer: parsedCustomer },
      path: `servicelogs/{token}/create-document/${parseCustomerName(customerName)}`,
    });

    if (doErr) return;

    navigate(
      `/servicelogs/?id=${customerId}&name=${customerName}&idToOpen=${data._id}`
    );
  };

  const extraActions = [
    {
      text: 'Skapa servicelogg',
      action: createServicelogFromOffer,
    },
  ];

  return <DocumentPage context={context} extraActions={extraActions} />;
};

export default OffersPage;
